<template>
<div>
    <ProjectPageTemplate
        projectTitle="3D RAYCASTER"
        :imageSource="require('@/assets/images/projectLinks/raycasterLink.png')"
        shortDescription="NOT SUITABLE FOR MOBILE - Click play to start. A 3D raycaster level using tile based collisions. Use up and down arrows to move forward and backward and left and right to turn"
        longDescription= "Coded in JavaScript and adapted from a number of raycasting tutorials online made in Scratch and C languages. Currently using 2D rendering context on an HTML canvas the rather than webgl at this time. Calculates the distance of the player to walls by projecting a ray on tile based grid and registering when it hits a solid tile. This distance is then used to determine the height of wall to be drawn. This is repeated for each scanline to create the 3D view." 
                        
    >
    <template v-slot:main-content>
        <UnableToDisplay
        :imageSource="require('@/assets/images/shadowExpand.svg')"
        text="SCREEN SIZE TOO SMALL"
        />
    </template>

    <template v-slot:applications-used>
       <ExternalLink
        webLink="https://code.visualstudio.com/"
        :imageSource="require('@/assets/images/linkIcons/vsc.png')"
      />

    </template>

    </ProjectPageTemplate>

</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import UnableToDisplay from '@/components/page_elements/UnableToDisplay.vue';
import ExternalLink from "@/components/ExternalLink.vue"
export default {
    // name: 'raycaster',
    components: {
        ProjectPageTemplate,
        UnableToDisplay,
        // raycasterGame,
        ExternalLink
    }
}
</script>

<style lang="scss" scoped>

</style>